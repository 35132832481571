import '../scss/index.scss';
import './polyfills';

var webBannerTracking = false;

document.querySelector('.homeslider_extra-container').addEventListener('click', function () {
    if (!webBannerTracking) {
        var xmlhttp = new XMLHttpRequest();

        xmlhttp.onreadystatechange = function()
        {
            if (xmlhttp.readyState == XMLHttpRequest.DONE)
            {
                if (xmlhttp.status == 200)
                {
                    //Put generated HTML into menu
                    if (xmlhttp.responseText !== '') {
                        webBannerTracking = true;
                        //console.log(xmlhttp.responseText);
                    }
                }
            }
        };

        xmlhttp.open("POST", "/index.php?fc=module&module=teapotwebbannertracking&controller=tracking", true);
        xmlhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        var payload = "ajax=1";
        xmlhttp.send(payload);
    }
});